var render = function () {
  var _vm$participantLocal, _vm$participantLocal2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "tournament-participant-lineup-modal",
      "title": _vm.title,
      "size": "lg"
    },
    on: {
      "hidden": _vm.resetModal,
      "show": _vm.findAvailablePlayers
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var cancel = _ref.cancel;
        return [_c('b-row', [_c('dismissible-message-box', {
          attrs: {
            "show": _vm.showErrorNotifications,
            "variant": _vm.MessageType.ERROR
          },
          on: {
            "onDismissibleMessageBoxClosed": function onDismissibleMessageBoxClosed($event) {
              _vm.showErrorNotifications = false;
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "InfoIcon"
          }
        }), _vm._v(" " + _vm._s(_vm.errors[0]) + " ")], 1), _c('dismissible-message-box', {
          attrs: {
            "show": _vm.showSuccessNotifications,
            "variant": _vm.MessageType.SUCCESS
          },
          on: {
            "onDismissibleMessageBoxClosed": function onDismissibleMessageBoxClosed($event) {
              _vm.showSuccessNotifications = false;
            }
          }
        }, [_vm._v(" Successfully updated lineup ")])], 1), _c('b-row', {
          staticClass: "justify-content-between"
        }, [_c('b-col', [_c('spinning-button', {
          attrs: {
            "is-loading": _vm.saving
          },
          nativeOn: {
            "click": function click($event) {
              return _vm.handleSaveClick($event);
            }
          }
        }, [_vm._v(" Save ")])], 1), _c('b-col', {
          staticClass: "d-flex justify-content-end"
        }, [_c('spinning-button', {
          nativeOn: {
            "click": function click($event) {
              return cancel($event);
            }
          }
        }, [_vm._v(" Close ")])], 1)], 1)];
      }
    }])
  }, [_c('b-row', [_c('b-col', [_c('div', {
    staticClass: "join-tournament p-2"
  }, [_c('div', {
    staticClass: "available-player-list mb-2"
  }, [_c('h5', {
    staticClass: "text-primary font-weight-bold"
  }, [_vm._v(" Team members: ")]), _c('draggable', {
    staticClass: "list-group list-group-flush  cursor-move d-flex flex-row",
    attrs: {
      "list": _vm.availablePlayers,
      "group": "players",
      "tag": "div"
    }
  }, _vm._l(_vm.availablePlayers, function (player) {
    return _c('b-list-group-item', {
      key: player === null || player === void 0 ? void 0 : player.player.id,
      staticClass: "flex-fill ",
      attrs: {
        "tag": "div"
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('b-avatar', {
      attrs: {
        "src": player === null || player === void 0 ? void 0 : player.player.avatar_image,
        "variant": "primary"
      }
    }), _c('div', {
      staticClass: "ml-25"
    }, [_c('b-card-text', {
      staticClass: "mb-0 font-weight-bold"
    }, [_vm._v(" " + _vm._s(player === null || player === void 0 ? void 0 : player.player.name) + " ")])], 1)], 1)]);
  }), 1)], 1), _c('div', {
    staticClass: "player-list"
  }, [_c('h5', {
    staticClass: "text-primary font-weight-bold"
  }, [_vm._v(" Lineup: ")]), _c('draggable', {
    staticClass: "list-group list-group-flush cursor-move",
    attrs: {
      "list": (_vm$participantLocal = _vm.participantLocal) === null || _vm$participantLocal === void 0 ? void 0 : _vm$participantLocal.lineup,
      "group": "players",
      "tag": "div"
    }
  }, _vm._l((_vm$participantLocal2 = _vm.participantLocal) === null || _vm$participantLocal2 === void 0 ? void 0 : _vm$participantLocal2.lineup, function (listItem) {
    return _c('b-list-group-item', {
      key: listItem.player.id,
      attrs: {
        "tag": "div"
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('b-avatar', {
      attrs: {
        "src": listItem.player.avatar_image,
        "variant": "primary"
      }
    }), _c('div', {
      staticClass: "ml-25"
    }, [_c('b-card-text', {
      staticClass: "mb-0 font-weight-bold"
    }, [_vm._v(" " + _vm._s(listItem.player.name) + " ")])], 1)], 1)]);
  }), 1)], 1)])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }