var render = function () {
  var _vm$participant, _vm$participant$parti, _vm$participant2, _vm$participant3, _vm$participant4, _vm$participant5;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "p-2 h-100 w-100 m-0",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "d-flex flex-column align-items-center"
  }, [_c('b-avatar', {
    staticClass: "mb-2",
    attrs: {
      "src": (_vm$participant = _vm.participant) === null || _vm$participant === void 0 ? void 0 : (_vm$participant$parti = _vm$participant.participant) === null || _vm$participant$parti === void 0 ? void 0 : _vm$participant$parti.avatar_image,
      "variant": "light-primary",
      "size": "100"
    }
  }), _c('h4', {
    staticClass: "mb-2"
  }, [_vm._v(" " + _vm._s((_vm$participant2 = _vm.participant) !== null && _vm$participant2 !== void 0 && _vm$participant2.participant ? _vm.participant.participant.name : _vm.participant.name) + " ")])], 1)], 1), ((_vm$participant3 = _vm.participant) === null || _vm$participant3 === void 0 ? void 0 : _vm$participant3.participant_type) === _vm.TournamentParticipantType.team ? _c('b-row', {
    staticClass: "mb-1"
  }, [_c('b-col', {
    staticClass: "text-center"
  }, [!_vm.participant.lineup || _vm.participant.lineup.length === 0 ? _c('div', [_c('i', [_vm._v("No lineup")])]) : _vm._e(), _vm._l((_vm$participant4 = _vm.participant) === null || _vm$participant4 === void 0 ? void 0 : _vm$participant4.lineup, function (lineup) {
    return _c('b-badge', {
      key: lineup.id,
      staticClass: "mt-1 mr-1 lineup-badge text-truncate",
      staticStyle: {
        "max-width": "150px"
      },
      attrs: {
        "disabled": lineup.name && lineup.participant === null,
        "to": lineup.participant ? {
          name: 'player-profile',
          params: {
            id: lineup.participant.id
          }
        } : null,
        "variant": "light-primary"
      }
    }, [_vm._v(" " + _vm._s(lineup.player ? lineup.player.name : lineup.name) + " ")]);
  })], 2)], 1) : _vm._e(), _vm.canEditLineup ? _c('b-row', {
    staticClass: "mt-auto mb-1"
  }, [_c('b-col', {
    staticClass: "text-center"
  }, [_c('b-button', {
    attrs: {
      "size": "sm",
      "variant": "primary"
    },
    on: {
      "click": _vm.handleEditLineupClick
    }
  }, [_vm._v(" Edit lineup ")])], 1)], 1) : _vm._e(), _c('b-row', {
    staticClass: "mt-auto"
  }, [_c('b-col', {
    staticClass: "text-center"
  }, [((_vm$participant5 = _vm.participant) === null || _vm$participant5 === void 0 ? void 0 : _vm$participant5.participant) !== null ? _c('b-button', {
    attrs: {
      "size": "sm",
      "variant": "primary"
    },
    on: {
      "click": _vm.handleProfileClick
    }
  }, [_vm._v(" Profile ")]) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }