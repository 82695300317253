var render = function () {
  var _vm$participants;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "height": "inherit"
    }
  }, [_c('b-card', {
    staticClass: "p-2",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-row', {
    staticClass: "align-items-center justify-content-between"
  }, [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('h5', {
    staticClass: "m-0"
  }, [_vm._v(" Tournament participants ")])]), _c('b-col', {
    staticClass: "d-flex justify-content-end",
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Search participants ...",
      "size": "lg",
      "type": "search"
    },
    model: {
      value: _vm.searchQuery,
      callback: function callback($$v) {
        _vm.searchQuery = $$v;
      },
      expression: "searchQuery"
    }
  }), _c('b-input-group-append', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "height": "20px",
      "icon": "SearchIcon",
      "width": "20px"
    }
  })], 1)], 1)], 1)], 1)], 1), _vm.isLoading ? _c('b-row', {
    staticClass: "text-center p-2"
  }, [_c('b-spinner', {
    attrs: {
      "label": "Loading..."
    }
  })], 1) : _vm._e(), _c('vue-perfect-scrollbar', {
    staticClass: "scroll-area",
    attrs: {
      "settings": _vm.perfectScrollbarSettings
    }
  }, [!_vm.isLoading ? _c('b-row', {
    staticClass: "m-0"
  }, [_vm.showJoinTournamentCard && !_vm.myRegistrations ? _c('b-col', {
    staticClass: "mb-1",
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "4"
    }
  }, [_c('b-card', {
    staticClass: "p-2 h-100 w-100",
    attrs: {
      "no-body": ""
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_vm._v(" You did not join this tournament yet. Hurry up and join before all available spots are taken by others. ")]), _c('div', {
    staticClass: "text-center mt-2"
  }, [_c('b-link', {
    staticClass: "btn-tour-skip mr-1",
    attrs: {
      "size": "sm",
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.handleSaveRegistrationClick
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "UserPlusIcon",
      "size": "50"
    }
  })], 1)], 1)])], 1) : _vm._e(), _vm.showRegistrationCard ? _c('b-col', {
    staticClass: "mb-1",
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "4"
    }
  }, [_c('b-card', {
    staticClass: "p-2 h-100 w-100",
    attrs: {
      "no-body": ""
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_vm._v(" Your team registration is waiting for approval. You will see your team here once it is approved. ")]), _c('div', {
    staticClass: "text-center mt-auto"
  }, [_c('feather-icon', {
    attrs: {
      "icon": "InfoIcon",
      "size": "50"
    }
  })], 1), _vm.canCancelRegistration(_vm.tournament) ? _c('div', {
    staticClass: "text-center mt-auto"
  }, [_c('b-button', {
    attrs: {
      "size": "sm",
      "variant": "danger"
    },
    on: {
      "click": _vm.handleWithdrawTournamentClick
    }
  }, [_vm.isCancelingRegistration ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm._v(" Withdraw from tournament ")], 1)], 1) : _vm._e()])], 1) : _vm._e(), !_vm.isLoading && (!_vm.participants || ((_vm$participants = _vm.participants) === null || _vm$participants === void 0 ? void 0 : _vm$participants.length) === 0) ? _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-center flex-column"
  }, [_c('div', {
    staticClass: "font-italic m-2 d-flex justify-content-center"
  }, [_c('feather-icon', {
    attrs: {
      "icon": "InfoIcon",
      "size": "40"
    }
  })], 1), _c('div', {
    staticClass: "font-italic d-flex justify-content-center"
  }, [_vm._v(" No participants joined this tournament. ")])])]) : _vm._e(), _vm._l(_vm.participants, function (participant) {
    return _c('b-col', {
      key: participant.id,
      staticClass: "d-flex mb-1",
      attrs: {
        "md": "6",
        "sm": "12",
        "lg": "4"
      }
    }, [_c('tournament-participant-card', {
      key: participant.id,
      attrs: {
        "participant": participant,
        "tournament": _vm.tournament
      },
      on: {
        "editLineupClicked": _vm.handleEditLineupClick
      }
    })], 1);
  })], 2) : _vm._e()], 1), _c('tournament-participant-lineup-modal', {
    attrs: {
      "participant": _vm.selectedParticipant,
      "tournament": _vm.tournament
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }