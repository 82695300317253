<template>
  <b-modal
    id="tournament-participant-lineup-modal"
    :title="title"
    size="lg"
    @hidden="resetModal"
    @show="findAvailablePlayers"
  >
    <!-- Edit lineup -->
    <b-row>
      <b-col>
        <div class="join-tournament p-2">
          <!-- Available players -->
          <div
            class="available-player-list mb-2"
          >
            <h5 class="text-primary font-weight-bold">
              Team members:
            </h5>

            <draggable
              :list="availablePlayers"
              class="list-group list-group-flush  cursor-move d-flex flex-row"
              group="players"
              tag="div"
            >
              <b-list-group-item
                v-for="player in availablePlayers"
                :key="player?.player.id"
                tag="div"
                class="flex-fill "
              >
                <div class="d-flex align-items-center">
                  <b-avatar
                    :src="player?.player.avatar_image"
                    variant="primary"
                  />
                  <div class="ml-25">
                    <b-card-text class="mb-0 font-weight-bold">
                      {{ player?.player.name }}
                    </b-card-text>
                  </div>
                </div>
              </b-list-group-item>
            </draggable>
          </div>

          <!-- Lineup -->
          <div
            class="player-list"
          >
            <h5 class="text-primary font-weight-bold">
              Lineup:
            </h5>

            <!-- draggable -->
            <draggable
              :list="participantLocal?.lineup"
              class="list-group list-group-flush cursor-move"
              group="players"
              tag="div"
            >
              <b-list-group-item
                v-for="listItem in participantLocal?.lineup"
                :key="listItem.player.id"
                tag="div"
              >
                <div class="d-flex align-items-center">
                  <b-avatar
                    :src="listItem.player.avatar_image"
                    variant="primary"
                  />

                  <div class="ml-25">
                    <b-card-text class="mb-0 font-weight-bold">
                      {{ listItem.player.name }}
                    </b-card-text>
                  </div>
                </div>
              </b-list-group-item>
            </draggable>
          </div>
        </div>
      </b-col>
    </b-row>

    <template #modal-footer="{cancel}">
      <b-row>
        <dismissible-message-box
          :show="showErrorNotifications"
          :variant="MessageType.ERROR"
          @onDismissibleMessageBoxClosed="showErrorNotifications = false"
        >
          <feather-icon
            class="mr-50"
            icon="InfoIcon"
          />
          {{ errors[0] }}
        </dismissible-message-box>

        <dismissible-message-box
          :show="showSuccessNotifications"
          :variant="MessageType.SUCCESS"
          @onDismissibleMessageBoxClosed="showSuccessNotifications = false"
        >
          Successfully updated lineup
        </dismissible-message-box>
      </b-row>

      <!-- Update lineup -->
      <b-row class="justify-content-between">
        <b-col>
          <spinning-button
            :is-loading="saving"
            @click.native="handleSaveClick"
          >
            Save
          </spinning-button>
        </b-col>

        <b-col class="d-flex justify-content-end">
          <spinning-button
            @click.native="cancel"
          >
            Close
          </spinning-button>
        </b-col>
      </b-row>

    </template>
  </b-modal>
</template>

<script>
import {
  BAvatar,
  BCardText,
  BCol,
  BListGroupItem,
  BModal,
  BRow,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import draggable from 'vuedraggable'
import TournamentParticipantType from '@/constants/TournamentParticipantType'
import SpinningButton from '@/components/SpinningButton.vue'
import DismissibleMessageBox from '@/components/DismissibleMessageBox.vue'
import MessageType from '@/constants/MessageType'
import { MemberRoleType } from '@/constants/MemberRoleType'

export default {
  components: {
    DismissibleMessageBox,
    SpinningButton,
    BModal,
    BAvatar,
    BListGroupItem,
    BRow,
    BCol,
    draggable,
    BCardText,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    participant: {
      type: Object,
      default: () => {
      },
    },
    tournament: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      MessageType,
      TournamentParticipantType,
      MemberRoleType,
      availablePlayers: [],
      lineup: [],
      backup: [],
      showErrorNotifications: false,
      showSuccessNotifications: false,
      errors: [],
      saving: false,
      cancelingRegistration: false,
      participantLocal: this.participant,
      localTournament: this.tournament,
    }
  },
  computed: {
    title() {
      // eslint-disable-next-line quotes
      return `Update team lineup`
    },
    me() {
      return this.$store.getters['user/getMe']
    },
  },
  watch: {
    tournament(value) {
      this.localTournament = value
    },
    participant: {
      handler(value) {
        this.participantLocal = JSON.parse(JSON.stringify(value))

        this.findAvailablePlayers()
      },
      deep: true,
    },
  },
  methods: {
    findAvailablePlayers() {
      if (!this.participantLocal) {
        return
      }

      if (this.me?.teams?.length > 0) {
        const selectedTeam = this.me.teams.find(team => team.id === this.participantLocal.participant_id)

        if (selectedTeam) {
          const availableUsers = selectedTeam.members.filter(member => (!this.participantLocal?.lineup.some(l => l.player.id === member.user.id)))

          this.availablePlayers = availableUsers.map(user => ({
            player: user.user,
            participant_id: user.user.id,
          }))
        }
      }
    },
    resetModal() {
      this.errors = []
      this.showErrorNotifications = false
      this.showSuccessNotifications = false
      this.cancelingRegistration = false
      this.availablePlayers = []
      this.participantLocal = null
      // this.localTournament = null
    },
    async handleSaveClick() {
      this.errors = []
      this.showErrorNotifications = false
      this.showSuccessNotifications = false
      this.cancelingRegistration = false

      if (
        !this.participantLocal?.lineup || this.participantLocal?.lineup?.length === 0
          || (this.localTournament.participant_type_id === TournamentParticipantType.team
              && this.participantLocal?.lineup?.length < this.localTournament.players_per_team)
      ) {
        this.showErrorNotifications = true

        this.errors.push(
          'Not enough players selected',
        )

        return
      }

      // eslint-disable-next-line no-unused-vars
      const payload = {
        tournament_id: this.localTournament.id,
        participant_type_id: this.localTournament.participant_type_id,
        // name: this.participantLocal.name,
        // email: this.participantLocal.email,
        // team/player id
        participant_id: this.participantLocal.participant_id,
        id: this.participantLocal?.id || null,
        lineup: this.participantLocal?.lineup.map(l => ({
          id: l.id,
          name: l.name,
          user_id: l.player.id,
        })),
      }

      // eslint-disable-next-line no-unreachable
      this.saving = true

      const { success } = await this.$api.tournament.saveParticipant(payload)

      this.saving = false

      if (success === false) {
        this.errors.push(
          'There was an error while saving your lineup. Please try again later or contact our support.',
        )

        this.showErrorNotifications = true

        return
      }

      this.showSuccessNotifications = true

      setTimeout(async () => {
        await this.$store.dispatch('tournament/fetchTournament', this.tournament.id)

        this.$bvModal.hide('tournament-participant-lineup-modal')
      }, 3000)
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/_variables.scss';

.dark-layout {
  .player-list {
    .list-group {
      flex-wrap: wrap;
      flex-direction: row;
      border: 1px dashed #7367f0;

      .list-group-item {
        flex: 1;
        border: none;
        max-width: 40%;

        &:hover,
        &:active {
          background: none !important;
        }
      }

      &:empty {
        color: #d0d2d6;
        font-size: 0.857rem;
        padding: 1rem;
        text-align: center;

        &:before {
          content: 'No players selected. Hold and drop an available player here.';
        }
      }
    }
  }

  .available-player-list {
    .list-group {
      flex-wrap: wrap;
      flex-direction: row;
      border: 1px dashed  #7367f0;

      .list-group-item {
        flex: 1;
        border: none;
        max-width: 40%;

        &:hover,
        &:active {
          background: none !important;
        }
      }

      &:empty {
        color: #d0d2d6;
        font-size: 0.857rem;
        padding: 1rem;
        text-align: center;

        &:before {
          content: 'Hold and drop a player below if you want to remove it.';
        }
      }
    }
  }
}
</style>
